<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0">
                  플랜 리스트
                  <v-select
                    v-model="configData.workSelected"
                    class="priority"
                    :items="workNames"
                    item-color="grey"
                    placeholder="플랜 선택"
                    outlined
                    dark
                    dense
                  />
                </v-col>
              </v-row>
                <v-row class="ma-0 pa-0">
                  <v-col class="ma-0 pa-0">
                    우선 순위
                    <v-select
                      v-model="configData.selected"
                      class="priority"
                      :items="priorityOptions"
                      item-color="grey"
                      placeholder="1"
                      height="8vh"
                      outlined
                      dark
                    />
                  </v-col>
                </v-row>
                <v-row class="ma-0 pa-0">
                  <v-col class="ma-0 pa-0">
                    수행 횟수 <br>
                    <input
                      v-model="configData.inputNumber"
                      type="number"
                      min="0"
                      max="99"
                      class="input-task-number"
                      placeholder="00"
                    >
                  </v-col>
                </v-row>
              <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0">
                  시간 <br>
                  <input
                    v-model="configData.hour"
                    type="number"
                    min="0"
                    max="24"
                    class="input-task-number"
                    placeholder="00"
                  > : <input
                    v-model="configData.min"
                    type="number"
                    min="0"
                    max="60"
                    class="input-task-number"
                    placeholder="00"
                  >
                </v-col>
              </v-row>
            </slot>
          </div>

          <v-row class="modal-footer ma-0 pa-0">
            <v-col
              class="btn-cancel pa-0"
              cols="6"
              type="button"
              @click="$emit('close')"
            >
              취소
            </v-col>
            <v-col
              class="btn-action pa-0"
              cols="6"
              type="button"
              @click="$emit('action')"
            >
              생성
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import EventBus from '@/main.js';

  export default {
    name: 'TimeTaskModal',
    props: {
      works: {
        type: Array,
        default: null,
      }
    },
    data() {
      return {
        priorityOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        configData: {
          selected: null,
          inputNumber: null,
          workSelected: null,
          hour: null,
          min: null,
        },
        workNames: [],
      }
    },
    created() {
      this.configData.inputNumber = 1;
      this.configData.selected = 5;
      this.configData.hour = 0;
      this.configData.min = 0;

      this.initialize();
      EventBus.$emit('taskConfig', this.configData);
    },
    updated() {
      EventBus.$emit('taskConfig', this.configData);
    },
    methods: {
      initialize() {
        this.works.forEach(work => {
          this.workNames.push(work.work_name);
        });
      },
    },
  }
</script>

<style lang="scss" scoped>

.button_gpio{
  margin: 3px;
  width: 70px;
  height: 70px;
  background-color: #777777;
  border-color: #773939;
  border-style:solid;
  border-width:5px;
  color: white;
  border-radius: 10px;
  cursor: pointer;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
  word-break: keep-all;
}

.modal-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.modal-container {
  width: 50%;
  height: 70%;
  margin: 0 auto;
  background-color: #393939;
  border-radius: 10px;
  transition: all .3s ease;
}

.modal-body {
  width: 100%;
  height: 60vh;
  padding: 7vh 0 0;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 3vw;
  text-align: center;
  color: #ffffff;
  overflow: auto;
}

.modal-text {
  margin: 50% 0 0;
}

.btn-cancel {
  text-align: center;
  background-color: #c4c4c4;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 3vw;
}

.btn-action {
  text-align: center;
  background-color: #7c7c7c;
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 3vw;
}

.priority {
  width: 60%;
  margin: 0 auto !important;
  background-color: black;
  border: 2px solid #545861;
}

.input-task-number {
  width: 30%;
  height: 8vh;
  margin: 1vh 0 0;
  padding: 20px;
  border: 2px solid #545861;
  background-color: black;
  font-family: Noto Sans KR,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 3vw;
  color: white;
}

@media (max-width: 1024px) {
  .button_gpio{
    margin: 3px;
    width: 40px;
    height: 40px;
    background-color: #777777;
    border-color: #773939;
    border-style:solid;
    border-width:5px;
    color: white;
    border-radius: 10px;
    cursor: pointer;
  }

}
</style>

<style>

.background .modal-mask > .modal-wrapper > .modal-container > .modal-body > .row > .col > .priority > .v-input__control > .v-input__slot {
  margin: 0;
  text-align: center;
  font-size: 3vw;
}

.background .modal-mask > .modal-wrapper > .modal-container > .modal-body > .row > .col > .priority > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections {
  max-height: none !important;
}

.background .modal-mask > .modal-wrapper > .modal-container > .modal-body > .row > .col > .priority > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections > .v-select__selection--comma {
  overflow: inherit;
}

.background .modal-mask > .modal-wrapper > .modal-container > .modal-body > .row > .col > .priority > .v-input__control > .v-text-field__details {
  display: none;
}
</style>
